@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Global styles */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Press Start 2P'; /* Retro font */
  background-image: url('../public/background.png');
  background-repeat: repeat; /* Repeat the image to create a tiled effect */
  display: flex;
  flex-direction: column;
  cursor: url('../public/cursor.png'), auto;
}

a, button, input, textarea, select {
  cursor: url('../public/cursor.png'), auto; /* Same custom cursor for interactive elements */
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li, a {
  line-height: 1.5; /* Adjust this value for more or less spacing */
}

h1, h2, h3, h4, h5, h6 {
  color: #ff4c4c; /* Red color for headings */
  margin: 0 0 10px 0;
}

p, a {
  color: #ffffff; /* White text for better readability */
}

ul, li {
  color: #00e5ff; /* Cyan/light blue color */
}


img {
  max-width: 30vw;
  max-height: 30vh;
  height: auto;
  border-radius: 25px;
  margin: 10px 20px;
}