.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 95%;
}

.glow-container {
  background-color: #1c1c1c; /* Slightly lighter background for contrast */
  border: 3px solid;
  border-radius: 10px;
  padding: 20px 50px;
  margin: 50px 25px;
}

.page-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-wrap {
  text-align: left;
}

.img-small {
  max-height: 200px;
  max-width: 200px;
}

.contact-icon {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  transition: transform;
}

.contact-icon:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px; /* Added padding for vertical space */
  background-color: #333; /* Dark background for retro contrast */
  border-bottom: 3px solid #ff4c4c; /* Retro vibrant red border */
}

.navbar-title {
  color: #ffdb3a; /* Bright yellow for a retro feel */
  margin: 0; /* Remove default margin */
}

.navbar-links {
  display: flex;
  gap: 20px; /* Space between links */
}

.navbar-links a {
  color: #ffffff; /* White color for links */
  text-decoration: none;
  padding: 5px 10px; /* Padding for button-like appearance */
  display: flex;
  align-items: center;
  border: 2px solid transparent; /* Initial border for hover effect */
}

.navbar-links a:hover {
  animation: blink-border 0.5s infinite; /* Blinking border animation */
}

@keyframes blink-border {
  0%, 49% {
    border-color: #ff4c4c; /* Visible border color */
  }
  50%, 100% {
    border-color: transparent; /* Transparent border */
  }
}