/* Neon Pink Glow */
.neon-pink-glow {
  border-color: #FF6EC7; /* Neon Pink */
  animation: neon-pink-pulse-shadow 2s infinite;
}

@keyframes neon-pink-pulse-shadow {
  0%, 100% {
    box-shadow: 0px 0px 15px rgba(255, 110, 199, 0.5);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(255, 110, 199, 0.8);
  }
}

/* Electric Blue Glow */
.electric-blue-glow {
  border-color: #007FFF; /* Electric Blue */
  animation: electric-blue-pulse-shadow 2s infinite;
}

@keyframes electric-blue-pulse-shadow {
  0%, 100% {
    box-shadow: 0px 0px 15px rgba(0, 127, 255, 0.5);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(0, 127, 255, 0.8);
  }
}

/* Lime Green Glow */
.lime-green-glow {
  border-color: #BFFF00; /* Lime Green */
  animation: lime-green-pulse-shadow 2s infinite;
}

@keyframes lime-green-pulse-shadow {
  0%, 100% {
    box-shadow: 0px 0px 15px rgba(191, 255, 0, 0.5);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(191, 255, 0, 0.8);
  }
}

/* Bright Yellow Glow */
.bright-yellow-glow {
  border-color: #FFDB3A; /* Bright Yellow */
  animation: bright-yellow-pulse-shadow 2s infinite;
}

@keyframes bright-yellow-pulse-shadow {
  0%, 100% {
    box-shadow: 0px 0px 15px rgba(255, 219, 58, 0.5);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(255, 219, 58, 0.8);
  }
}

/* Vibrant Orange Glow */
.vibrant-orange-glow {
  border: 3px solid #FFA500; /* Vibrant Orange */
  animation: orange-pulse-shadow 2s infinite;
}

@keyframes orange-pulse-shadow {
  0%, 100% {
    box-shadow: 0px 0px 15px rgba(255, 165, 0, 0.5);
  }
  50% {
    box-shadow: 0px 0px 30px rgba(255, 165, 0, 0.8);
  }
}

.jiggle {
  animation: jiggle-animation 1s infinite;
}

@keyframes jiggle-animation {
  0%, 49% {
    transform: rotate(0deg);
  }
  50%, 100% {
    transform: rotate(5deg);
  }
}

.up-down {
  animation: up-down-animation 1s infinite;
}

@keyframes up-down-animation {
  0%, 49% {
    transform: translateY(0);
  }
  50%, 100% {
    transform: translateY(-10px);
  }
}

.scale {
  animation: scale-animation 1s infinite;
}

@keyframes scale-animation {
  0%, 49% {
    transform: scale(1);
  }
  50%, 100% {
    transform: scale(0.95);
  }
}

.left-right {
  animation: left-right-animation 1s infinite;
}

@keyframes left-right-animation {
  0%, 49% {
    transform: translateX(0);
  }
  50%, 100% {
    transform: translateX(-10px);
  }
}